import 'owl.carousel'

// Slider Home
let resp = {0: {center: true}};
resp[sizes.S] = {center: false};
$(window).on('load', function () {
  $('[data-carusel="try-box"]').owlCarousel({
    dots: false,
    margin: 0,
    items: 3,
    loop: false,
    responsive: resp
  });
});


// Vertical Scroll prevent
window.touch_ = false;
$(document).on('touchstart', function(e) {
  window.touch_ = { x: e.pageX, y: e.pageY };
});

$(document).on('touchend', function() {
  window.touch_ = false;
});

$('[data-carusel]')[0].addEventListener('touchmove', function (e) {
  let dx = Math.abs(window.touch_.x - e.pageX);
  let dy = Math.abs(window.touch_.y - e.pageY);
  if (dx > dy) e.preventDefault();
}, { passive: false });


// Rain
let factor = $(window).width()/1500;

setInterval(function () {
  let height = $('[data-group="dlja-grusti-i-toski"]').height() + 50;
  let scale = Math.random();

  let blob = $('<img class="blob" src="/static/images/blob.svg" />');
  blob.css({
    left: Math.random() * $(window).width(),
    width: 9 + 10 * scale * factor,
    top: '-50px'
  }).animate({
    top: height + 'px'
  }, 900 + scale * 1500);
  blob.appendTo('[data-group="dlja-grusti-i-toski"]');

  setTimeout(function () {
    blob.remove();
  }, 2000);
}, 180 + 400 * factor);

if (localStorage) {
  localStorage.setItem('collection', false);
  localStorage.setItem('base', '/');
}

// Ящик с глазами :D
let pb = $('[data-postbox]');
let postbox = false;
let pbshow = false;

setInterval(() => {
  postbox = pb.offset();
  postbox.left += pb.width()/2;
  postbox.top += pb.height()/2;
}, 3 * 1000);

let eye = $('[data-eye]');
const d = 3;

if ($(window).width() > sizes.M) {
  $(document).on('mousemove', (e) => {
    if (!postbox) return;
    let vec = {x: e.pageX - postbox.left, y: postbox.top - e.pageY};
    let len = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
    if (pbshow  && len < 100) { pb.addClass('hide'); pbshow = false; }
    if (!pbshow && len > 150) { pb.removeClass('hide'); pbshow = true; }

    eye.css({
      transform: 'translate('+(d * vec.x/len)+'px, '+(- d * vec.y/len)+'px)'
    });
  });
} else {
  pb.removeClass('hide');
  eye.css({
    transform: 'translate(-2px, -2px)'
  });
}

/*
setInterval(()=> {
  if (Math.random() > 0.8) return;
  pb.addClass('mig');
  setTimeout(() => pb.removeClass('mig'), 300);
  setTimeout(() =>    pb.addClass('mig'), 600);
  setTimeout(() => pb.removeClass('mig'), 900);
}, 3000);
*/

require('../scss/page-home.scss');
